import appstore from "../Assets/images/app-store-button.svg";
import googleplay from "../Assets/images/google-play-button.svg";
import downloadPng from "../Assets/images/download-app-desktop.png"
import downloadMobilePng from "../Assets/images/download-app-mobile.png"
export const DownloadApp = () => {
    return(
        <div data-test-id="download-app" class="download-app" data-v-a28c6148>
              <div class="download-app-image" data-v-a28c6148>
                <picture>
                  <source
                    srcset={`${downloadPng} 2160w, ${downloadPng} 1440w, ${downloadPng} 720w`}
                    media="(min-width: 1024px)" /> <img
                    src={downloadPng}
                    srcset={`${downloadMobilePng} 2010w, ${downloadMobilePng} 1340w, ${downloadMobilePng} 670w`}
                    sizes="(min-width: 600px) 720px, 133vw" role="presentation" width="670" height="739" loading="lazy"
                    class="download-app-image__image" />
                </picture>
              </div>
              <div class="download-app__text-container" data-v-a28c6148>
                <div class="download-app__inside-container" data-v-a28c6148>
                  {/* <img role="presentation" width="105"
                    height="122" class="download-app__icon" data-v-a28c6148 /> */}
                  <h2 class="heading-h2 download-app__title heading-h2--bigger" data-v-2013283c data-v-a28c6148>
                    Download the app
                  </h2>
                  <div class="download-app__subtitle" data-v-a28c6148>
                    Order anything and track it in real time with the Malgoeats app.
                  </div>
                  <div class="download-app__actions" data-v-a28c6148><a data-test-id="app-store-link" target="_blank"
                      rel="noopener" href="#"
                      data-v-a28c6148><img src={appstore} width="120" height="40" data-v-a28c6148 /></a> <a
                      data-test-id="google-play-link" target="_blank" rel="noopener"
                      href="#"
                      data-v-a28c6148 ><img src={googleplay} width="134" height="40" data-v-a28c6148 /></a></div>
                </div>
              </div>
        </div>
    )
}