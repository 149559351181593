import { Header } from "./Header"
import { Footer } from "./Footer"
import { DownloadApp } from './DowloadApp';

import appstore from "../Assets/images/app-store-button.svg";
import googleplay from "../Assets/images/google-play-button.svg";

import franchise from "../Assets/images/franchise.jpg"
import franchisehome from "../Assets/images/franchise.jpeg"

import '../Assets/style.css'


export const Franchise = () => {
 return (<>
    <Header />
    <section class="banner position-relative bg-brand-2 bgd bgd-cover text-white pb-5" style={{backgroundImage: `url(${franchise})`,paddingTop: '20px'}}>
        <div class="wrapper mt-4 mt-sm-0">
            <div class="container-fluid py-lg-5 my-xl-3">
                <h1 class="fw-semi-bold wow fadeIn animated" data-wow-duration=".8s" data-wow-delay="0.2s" style={{visibility: 'visible', animationDuration: '0.8s', animationDelay: '0.2s', animationName: 'fadeIn',display:'flex',justifyContent:'center',alignContent:'center',color:'#C22432',fontWeight:800}}>Become a Franchise</h1>
            </div>
        </div>
    </section>

    

            <div data-test-id="download-app" class="download-app partner_field" data-v-a28c6148>

            <div class="download-app__text-container" data-v-a28c6148>
                <div class="download-app__inside-container" data-v-a28c6148>
                            <div class="card card-sp border-0 radius-12 shadow">
                                <div class="card-body p-lg-5">
                                    <h4 class="fw-semi-bold text-dark">Join as franchisee in <span class="text-brand-2">Malgo Eats</span></h4>
                                    <p>Please provide your info, we will get in touch with you.</p>
                                    <form class="needs-validation" novalidate="" method="post" id="partner-form" onsubmit="return submitPartner()">
                            
                                        <input type="hidden" name="type" id="type" value="partner" />
                                        <div class="form-group is-floating-label label-inside required-item flex-shrink-0 mr-2">
                                            <label class="form-control-placeholder" for="">Name</label>
                                            <input type="text" class="form-control field bg-smoke" required="" name="name" id="name" onkeypress="return isAlfa(event)" maxlength="40" autocomplete="off" />
                                            {/* <div class="invalid-feedback text-left" id="name_msg">Enter name</div> */}
                                        </div>
                                        <div class="form-group is-floating-label label-inside required-item flex-shrink-0 mr-2">
                                            <label class="form-control-placeholder" for="">Email Address</label>
                                            <input type="email" class="form-control field bg-smoke" required="" name="email" id="email" autocomplete="off" />
                                            {/* <div class="invalid-feedback text-left" id="email_msg">Enter email address</div> */}
                                        </div>   
                                       <div class="d-flex">
                                            <div class="form-group w-size-100 flex-shrink-0 mr-2">
                                                <input type="text" class="form-control field bg-smoke" required="" placeholder="Ex: 91" name="code" id="code" minlength="3" maxlength="5" autocomplete="off" />
                                                {/* <div class="invalid-feedback text-left" id="code_msg">Country code</div> */}
                                            </div>
                                            <div class="form-group is-floating-label label-inside required-item flex-fill flex-shrink-0 mr-2">
                                                <label class="form-control-placeholder" for="">Phone Number</label>
                                                <input type="text" class="form-control field bg-smoke" pattern=".{11}" minlength="6" maxlength="11" required="" name="phone" id="phone" onkeypress="return onlyNumberKey(event)" autocomplete="off" />
                                                {/* <div class="invalid-feedback text-left" id="phone_msg">Enter phone number</div> */}
                                            </div>
                                       </div>

                                       <div class="form-group is-floating-label label-inside required-item flex-shrink-0 mr-2">
                                            <label class="form-control-placeholder" for="">Occupation</label>
                                            <input type="text" class="form-control field bg-smoke" required="" name="shopname" id="shopname" onkeypress="return isAlfa(event)" maxlength="25" autocomplete="off" />
                                            {/* <div class="invalid-feedback text-left" id="location_msg">Enter shop name</div> */}
                                        </div>

                                       <div class="form-group is-floating-label label-inside required-item flex-shrink-0 mr-2">
                                            <label class="form-control-placeholder" for="">Location Name</label>
                                            <input type="text" class="form-control field bg-smoke" required="" name="location" id="location" onkeypress="return isAlfa(event)" maxlength="25" autocomplete="off" />
                                            {/* <div class="invalid-feedback text-left" id="location_msg">Enter location name</div> */}
                                        </div>

                                        <div class="form-group is-floating-label label-inside required-item flex-shrink-0 mr-2">
                                            <label class="form-control-placeholder" for="">Address</label>
                                            <textarea rows="2" class="form-control field bg-smoke mh-150 resize-0" required="" name="address" id="address" maxlength="190" autocomplete="off"></textarea>
                                            {/* <div class="invalid-feedback text-left" id="address_msg">Enter address</div> */}
                                        </div>

                                        <div class="form-group">
                                        <button type="button" data-test-id="get-started-button" id="user-register" data-e2e-id="header-user-signup" class="helio-button corporate-element__button primary" data-v-25ebbc26="true" data-v-3d748f89="true" data-v-bd4115c8="true">  <span class="helio-button__content" data-v-25ebbc26="true">Submit</span></button>
                                        </div>

                                        <small><i class="fas fa-shield-alt mr-1"></i> We'll never share your email &amp; phone number with anyone else.</small>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>

            
              <div class="download-app-image" data-v-a28c6148>
                <picture>
                  <source
                    media="(min-width: 1024px)" /> <img
                    src={franchisehome}
                    sizes="(min-width: 600px) 720px, 133vw" role="presentation" width="600" height="600" loading="lazy"
                    class="download-app-image__image" />
                </picture>
              </div>
              
            </div>


   <DownloadApp />
    <Footer />
 </>)   
}