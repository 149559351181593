import './App.css';
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import {Landing} from './Components/Landing';
import {Partner} from './Components/Partner';
import {Franchise} from './Components/Franchise';
import {Wrapper} from './Components/Wrapper';
import {DeliveryPartner} from './Components/DeliveryPartner';

function App() {
  return (
    <Router>
      <Wrapper>

    <Routes>
      <Route exact path="/" element={<Landing />} />
      <Route path="/become-partner" element={<Partner />} />
      <Route path="/become-franchise" element={<Franchise />} />
      <Route path="/become-delivery" element={<DeliveryPartner />} />

      {/* <Route path="/about" element={<About />} /> */}
    </Routes>
    </Wrapper>
  </Router>
  );
}


export default App;
