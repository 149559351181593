import react, { Fragment } from 'react';
import {Link} from 'react-router-dom'; 
import '../Assets/style.css'
import bannr_video from "../Assets/images/address-container-animation.webm"
import { useNavigate } from "react-router-dom";

import { Footer } from './Footer';
import { Header } from './Header';
import { DownloadApp } from './DowloadApp';
import flagWhite from '../Assets/images/flag--white.svg'
import location from '../Assets/images/location.svg'
import sushiWide from '../Assets/images/sushi-wide.jpg'
import curryWide from '../Assets/images/curry-wide.jpg'
import nandos from '../Assets/images/nandos.jpg'
import desserWide from '../Assets/images/dessert-wide.jpg'
import iconDonut from '../Assets/images/icon-donut.jpg'
import wagamama from '../Assets/images/wagamama.jpg'
import fiveguys from '../Assets/images/fiveguys.jpg'
import chickenWide from '../Assets/images/chicken-wide.jpg'
import pizzawide from '../Assets/images/pizza-wide.jpg'
import iconVegan from '../Assets/images/icon-vegan.jpg'
import hop from '../Assets/images/hop.jpg'
import bowlWide from '../Assets/images/bowl-wide.jpg'
import thaiWide from '../Assets/images/thai-wide.jpg'
import iconPizza from '../Assets/images/icon-pizza.jpg'
import waitRose from '../Assets/images/waitrose.jpg'
import iconWine from '../Assets/images/icon-wine.jpg';
import delivery from '../Assets/images/delivery.svg'
import groceries from '../Assets/images/groceries.svg'
import countries from '../Assets/images/countries.svg'
import malgo1 from '../Assets/images/malgo1.png'
import malgo3 from '../Assets/images/malgo3.png'
import malgoRes from '../Assets/images/malgo_restaurant.png'

export const Landing = () => {

  let navigate = useNavigate(); 
  const routeChange = (path) =>{ 
      console.log(path,'path_test')
      navigate(path);
  }

return(
    <Fragment>
<body class="modal-overlay--above-all" data-n-head={{"class":{"ssr":"modal-overlay--above-all"}}}>
  <div data-server-rendered="true" id="__nuxt">
    <div id="__layout">
      <div className='custom-style'
        data-v-5e877c34><span data-v-5e877c34> </span> 
        <div id="default-wrapper" class="app-wrapper" data-v-5e877c34>
          <div data-test-id="landing-container" class="landing-container global-page-landing" data-v-5e877c34>
            
            <Header />

            <div data-test-id="address-container-section"
              class="global-jumbotron landing-container__jumbotron global-jumbotron--without-wave custom-style1"
            //   style="--address-jumbotron-wave-desktop:url(https://res.cloudinary.com/glovoapp/image/fetch/f_svg,q_auto:low/https://glovoapp.com/images/waves/address-jumbotron-wave-desktop.svg);--address-jumbotron-wave-mobile:url(https://res.cloudinary.com/glovoapp/image/fetch/f_svg,q_auto:low/https://glovoapp.com/images/waves/address-jumbotron-wave-mobile.svg);"
              data-v-334eb13b>
              <div data-test-id="address-animation" class="animation" data-v-f4daae2a data-v-334eb13b><video data-test-id="animation-video" autoplay="autoplay" loop="loop" muted="muted"
                  width="281" height="252" class="animation__element animation--minimize" data-v-f4daae2a>
                  <source
                    src={bannr_video}
                    preload="auto" type="video/webm" data-v-f4daae2a />
                </video></div>
              <div class="global-jumbotron__text-container" data-v-334eb13b>
                <h1 class="heading-h1 global-jumbotron__text-container__title" data-v-5e54a1af data-v-334eb13b style={{color:'#fff'}}>
                  Food delivery and more
                </h1>
                <p class="global-jumbotron__text-container__subtitle" data-v-334eb13b style={{color:'#ccc'}}>
                  Indian, chines, continental, and more!
                </p>
                <div data-test-id="address-input-container" class="address-container" data-v-012b135d data-v-334eb13b>
                  
                  <div class="address-container__input-wrapper" data-v-012b135d>
                    <div data-test-id="address-input" class="address-input__wrapper" data-v-1c57f5e2 data-v-012b135d>
                      <div data-test-id="address-input-title" class="address-input__title" 
                    style={{display:"none"}}
                        data-v-1c57f5e2 data-v-1909b318 ><span data-v-1c57f5e2>Enter your address to know
                          <br class="visible-mobile" data-v-1c57f5e2 /> <span
                            class="v-highlight v-highlight--white  v-highlight--medium v-highlight--book"
                            data-v-1c57f5e2><mark class="v-highlight-text v-highlight-text--medium"><span>what’s near
                                you</span></mark></span></span></div>
                      <div data-test-id="address-input" class="address-input" data-v-1c57f5e2>
                        <div data-test-id="address-input-button" class="address-input__container" data-v-1c57f5e2>
                          <div class="address-input__container__icon" data-v-1c57f5e2><img
                              src={flagWhite}
                              role="presentation" width="24" height="50" alt
                              class="address-input__container__icon__image" data-v-1c57f5e2 /></div>
                          <div data-test-id="address-input-placeholder" class="address-input__container__input"
                            data-v-1c57f5e2><span class="address-input__container__input--blinking"
                              data-v-1c57f5e2>|</span>
                            What's your address?
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="current-location-wrapper" data-v-37ccb6ed data-v-012b135d>
                      <div data-v-37ccb6ed data-v-1909b318>
                        <div data-test-id="use-current-location"
                          class="current-location current-location--insideAddressInput" data-v-37ccb6ed><img
                            loading="lazy"
                            src={location}
                            data-v-37ccb6ed />
                          <div class="current-location__text" data-v-37ccb6ed>
                            Use current location
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="ScrollingCarousel-e85b8d54755f4f6f">
  <div class="ScrollingCarousel-17705b2e86ed035e">
    <div class="ScrollingCarousel-501f73ba3d650ef7">
      <div class="ScrollingCarousel-a63526bda4ec23a2 ScrollingCarousel-7a0c7742e021e755" style={{left: "-66px", animationDuration: "260s"}}>
        <div class="ScrollingCard-019dec537a21e45f ScrollingCard-b8bd9043529b3574">
          <img src={sushiWide} alt="" class="ScrollingCard-5f7181f168ccd821 ScrollingCard-b8bd9043529b3574" />
        </div>
        <div class="ScrollingCard-019dec537a21e45f ScrollingCard-b8bd9043529b3574">
          <img src={curryWide} alt="" class="ScrollingCard-5f7181f168ccd821 ScrollingCard-b8bd9043529b3574" />
        </div>
        <div class="ScrollingCard-019dec537a21e45f">
          <img src={nandos} alt="" class="ScrollingCard-5f7181f168ccd821" />
        </div>
        <div class="ScrollingCard-019dec537a21e45f ScrollingCard-b8bd9043529b3574">
          <img src={desserWide} alt="" class="ScrollingCard-5f7181f168ccd821 ScrollingCard-b8bd9043529b3574" />
        </div>
        <div class="ScrollingCard-019dec537a21e45f">
          <img src={iconDonut} alt="" class="ScrollingCard-5f7181f168ccd821" />
        </div>
        <div class="ScrollingCard-019dec537a21e45f">
          <img src={wagamama} alt="" class="ScrollingCard-5f7181f168ccd821" />
        </div>
        <div class="ScrollingCard-019dec537a21e45f">
          <img src={fiveguys} alt="" class="ScrollingCard-5f7181f168ccd821" />
        </div>
        <div class="ScrollingCard-019dec537a21e45f ScrollingCard-b8bd9043529b3574">
          <img src={chickenWide} alt="" class="ScrollingCard-5f7181f168ccd821 ScrollingCard-b8bd9043529b3574" />
        </div>
        <div class="ScrollingCard-019dec537a21e45f ScrollingCard-b8bd9043529b3574">
          <img src={pizzawide} alt="" class="ScrollingCard-5f7181f168ccd821 ScrollingCard-b8bd9043529b3574" />
        </div>
        <div class="ScrollingCard-019dec537a21e45f">
          <img src={iconVegan} alt="" class="ScrollingCard-5f7181f168ccd821" />
        </div>
        <div class="ScrollingCard-019dec537a21e45f">
          <img src={hop} alt="" class="ScrollingCard-5f7181f168ccd821" />
        </div>
        <div class="ScrollingCard-019dec537a21e45f ScrollingCard-b8bd9043529b3574">
          <img src={bowlWide} alt="" class="ScrollingCard-5f7181f168ccd821 ScrollingCard-b8bd9043529b3574" />
        </div>
        <div class="ScrollingCard-019dec537a21e45f ScrollingCard-b8bd9043529b3574">
          <img src={thaiWide} alt="" class="ScrollingCard-5f7181f168ccd821 ScrollingCard-b8bd9043529b3574" />
        </div>
        <div class="ScrollingCard-019dec537a21e45f">
          <img src={iconPizza} alt="" class="ScrollingCard-5f7181f168ccd821" />
        </div>
        <div class="ScrollingCard-019dec537a21e45f">
          <img src={waitRose} alt="" class="ScrollingCard-5f7181f168ccd821" />
        </div>
        <div class="ScrollingCard-019dec537a21e45f">
          <img src={iconWine} alt="" class="ScrollingCard-5f7181f168ccd821" />
        </div>
        <div class="ScrollingCard-019dec537a21e45f ScrollingCard-b8bd9043529b3574">
          <img src={sushiWide} alt="" class="ScrollingCard-5f7181f168ccd821 ScrollingCard-b8bd9043529b3574" />
        </div>
        <div class="ScrollingCard-019dec537a21e45f ScrollingCard-b8bd9043529b3574">
          <img src={curryWide} alt="" class="ScrollingCard-5f7181f168ccd821 ScrollingCard-b8bd9043529b3574" />
        </div>
        <div class="ScrollingCard-019dec537a21e45f">
          <img src={nandos} alt="" class="ScrollingCard-5f7181f168ccd821" />
        </div>
        <div class="ScrollingCard-019dec537a21e45f ScrollingCard-b8bd9043529b3574">
          <img src={desserWide} alt="" class="ScrollingCard-5f7181f168ccd821 ScrollingCard-b8bd9043529b3574" />
        </div>
        <div class="ScrollingCard-019dec537a21e45f">
          <img src={iconDonut} alt="" class="ScrollingCard-5f7181f168ccd821" />
        </div>
        <div class="ScrollingCard-019dec537a21e45f">
          <img src={wagamama} alt="" class="ScrollingCard-5f7181f168ccd821" />
        </div>
        <div class="ScrollingCard-019dec537a21e45f">
          <img src={fiveguys} alt="" class="ScrollingCard-5f7181f168ccd821" />
        </div>
        <div class="ScrollingCard-019dec537a21e45f ScrollingCard-b8bd9043529b3574">
          <img src={chickenWide} alt="" class="ScrollingCard-5f7181f168ccd821 ScrollingCard-b8bd9043529b3574" />
        </div>
        <div class="ScrollingCard-019dec537a21e45f ScrollingCard-b8bd9043529b3574">
          <img src={pizzawide} alt="" class="ScrollingCard-5f7181f168ccd821 ScrollingCard-b8bd9043529b3574" />
        </div>
        <div class="ScrollingCard-019dec537a21e45f">
          <img src={iconVegan} alt="" class="ScrollingCard-5f7181f168ccd821" />
        </div>
        <div class="ScrollingCard-019dec537a21e45f">
          <img src={hop} alt="" class="ScrollingCard-5f7181f168ccd821" />
        </div>
        <div class="ScrollingCard-019dec537a21e45f ScrollingCard-b8bd9043529b3574">
          <img src={bowlWide} alt="" class="ScrollingCard-5f7181f168ccd821 ScrollingCard-b8bd9043529b3574" />
        </div>
        <div class="ScrollingCard-019dec537a21e45f ScrollingCard-b8bd9043529b3574">
          <img src={thaiWide} alt="" class="ScrollingCard-5f7181f168ccd821 ScrollingCard-b8bd9043529b3574" />
        </div>
        <div class="ScrollingCard-019dec537a21e45f">
          <img src={iconPizza} alt="" class="ScrollingCard-5f7181f168ccd821" />
        </div>
        <div class="ScrollingCard-019dec537a21e45f">
          <img src={waitRose} alt="" class="ScrollingCard-5f7181f168ccd821" />
        </div>
        <div class="ScrollingCard-019dec537a21e45f">
          <img src={iconWine} alt="" class="ScrollingCard-5f7181f168ccd821" />
        </div>
      </div>
    </div>
  </div>
  <div class="ScrollingCarousel-17705b2e86ed035e">
    <div class="ScrollingCarousel-501f73ba3d650ef7">
      <div class="ScrollingCarousel-a63526bda4ec23a2 ScrollingCarousel-7a0c7742e021e755" style={{animationDuration:"260s"}}>
        <div class="ScrollingCard-019dec537a21e45f ScrollingCard-b8bd9043529b3574">
          <img src={pizzawide} alt="" class="ScrollingCard-5f7181f168ccd821 ScrollingCard-b8bd9043529b3574" />
        </div>
        <div class="ScrollingCard-019dec537a21e45f">
          <img src={iconVegan} alt="" class="ScrollingCard-5f7181f168ccd821" />
        </div>
        <div class="ScrollingCard-019dec537a21e45f">
          <img src={hop} alt="" class="ScrollingCard-5f7181f168ccd821" />
        </div>
        <div class="ScrollingCard-019dec537a21e45f ScrollingCard-b8bd9043529b3574">
          <img src={bowlWide} alt="" class="ScrollingCard-5f7181f168ccd821 ScrollingCard-b8bd9043529b3574" />
        </div>
        <div class="ScrollingCard-019dec537a21e45f ScrollingCard-b8bd9043529b3574">
          <img src={thaiWide} alt="" class="ScrollingCard-5f7181f168ccd821 ScrollingCard-b8bd9043529b3574" />
        </div>
        <div class="ScrollingCard-019dec537a21e45f">
          <img src={iconPizza} alt="" class="ScrollingCard-5f7181f168ccd821" />
        </div>
        <div class="ScrollingCard-019dec537a21e45f">
          <img src={waitRose} alt="" class="ScrollingCard-5f7181f168ccd821" />
        </div>
        <div class="ScrollingCard-019dec537a21e45f">
          <img src={iconWine} alt="" class="ScrollingCard-5f7181f168ccd821" />
        </div>
        <div class="ScrollingCard-019dec537a21e45f ScrollingCard-b8bd9043529b3574">
          <img src={sushiWide} alt="" class="ScrollingCard-5f7181f168ccd821 ScrollingCard-b8bd9043529b3574" />
        </div>
        <div class="ScrollingCard-019dec537a21e45f ScrollingCard-b8bd9043529b3574">
          <img src={curryWide} alt="" class="ScrollingCard-5f7181f168ccd821 ScrollingCard-b8bd9043529b3574" />
        </div>
        <div class="ScrollingCard-019dec537a21e45f">
          <img src={nandos} alt="" class="ScrollingCard-5f7181f168ccd821" />
        </div>
        <div class="ScrollingCard-019dec537a21e45f ScrollingCard-b8bd9043529b3574">
          <img src={desserWide} alt="" class="ScrollingCard-5f7181f168ccd821 ScrollingCard-b8bd9043529b3574" />
        </div>
        <div class="ScrollingCard-019dec537a21e45f">
          <img src={iconDonut} alt="" class="ScrollingCard-5f7181f168ccd821" />
        </div>
        <div class="ScrollingCard-019dec537a21e45f">
          <img src={wagamama} alt="" class="ScrollingCard-5f7181f168ccd821" />
        </div>
        <div class="ScrollingCard-019dec537a21e45f">
          <img src={fiveguys} alt="" class="ScrollingCard-5f7181f168ccd821" />
        </div>
        <div class="ScrollingCard-019dec537a21e45f ScrollingCard-b8bd9043529b3574">
          <img src={chickenWide} alt="" class="ScrollingCard-5f7181f168ccd821 ScrollingCard-b8bd9043529b3574" />
        </div>
        <div class="ScrollingCard-019dec537a21e45f ScrollingCard-b8bd9043529b3574">
          <img src={pizzawide} alt="" class="ScrollingCard-5f7181f168ccd821 ScrollingCard-b8bd9043529b3574" />
        </div>
        <div class="ScrollingCard-019dec537a21e45f">
          <img src={iconVegan} alt="" class="ScrollingCard-5f7181f168ccd821" />
        </div>
        <div class="ScrollingCard-019dec537a21e45f">
          <img src={hop} alt="" class="ScrollingCard-5f7181f168ccd821" />
        </div>
        <div class="ScrollingCard-019dec537a21e45f ScrollingCard-b8bd9043529b3574">
          <img src={bowlWide} alt="" class="ScrollingCard-5f7181f168ccd821 ScrollingCard-b8bd9043529b3574" />
        </div>
        <div class="ScrollingCard-019dec537a21e45f ScrollingCard-b8bd9043529b3574">
          <img src={thaiWide} alt="" class="ScrollingCard-5f7181f168ccd821 ScrollingCard-b8bd9043529b3574" />
        </div>
        <div class="ScrollingCard-019dec537a21e45f">
          <img src={iconPizza} alt="" class="ScrollingCard-5f7181f168ccd821" />
        </div>
        <div class="ScrollingCard-019dec537a21e45f">
          <img src={waitRose} alt="" class="ScrollingCard-5f7181f168ccd821" />
        </div>
        <div class="ScrollingCard-019dec537a21e45f">
          <img src={iconWine} alt="" class="ScrollingCard-5f7181f168ccd821" />
        </div>
        <div class="ScrollingCard-019dec537a21e45f ScrollingCard-b8bd9043529b3574">
          <img src={sushiWide} alt="" class="ScrollingCard-5f7181f168ccd821 ScrollingCard-b8bd9043529b3574" />
        </div>
        <div class="ScrollingCard-019dec537a21e45f ScrollingCard-b8bd9043529b3574">
          <img src={curryWide} alt="" class="ScrollingCard-5f7181f168ccd821 ScrollingCard-b8bd9043529b3574" />
        </div>
        <div class="ScrollingCard-019dec537a21e45f">
          <img src={nandos} alt="" class="ScrollingCard-5f7181f168ccd821" />
        </div>
        <div class="ScrollingCard-019dec537a21e45f ScrollingCard-b8bd9043529b3574">
          <img src={desserWide} alt="" class="ScrollingCard-5f7181f168ccd821 ScrollingCard-b8bd9043529b3574" />
        </div>
        <div class="ScrollingCard-019dec537a21e45f">
          <img src={iconDonut} alt="" class="ScrollingCard-5f7181f168ccd821" />
        </div>
        <div class="ScrollingCard-019dec537a21e45f">
          <img src={wagamama} alt="" class="ScrollingCard-5f7181f168ccd821" />
        </div>
        <div class="ScrollingCard-019dec537a21e45f">
          <img src={fiveguys} alt="" class="ScrollingCard-5f7181f168ccd821" />
        </div>
        <div class="ScrollingCard-019dec537a21e45f ScrollingCard-b8bd9043529b3574">
          <img src={chickenWide} alt="" class="ScrollingCard-5f7181f168ccd821 ScrollingCard-b8bd9043529b3574" />
        </div>
      </div>
    </div>
  </div>
</div>

            <div data-test-id="top-partners-container" class="top-partners custom-style2"
             data-v-946ef96e>
              <h2 data-test-id="top-partners-title" class="top-partners__title" data-v-946ef96e ><span
                  class="top-partners__highlight v-highlight v-highlight--white  v-highlight--big v-highlight--undefined"
                  data-v-946ef96e><mark class="v-highlight-text v-highlight-text--big"><span>Top restaurants and
                      more</span></mark></span> in Malgo Eats</h2>
              <div class="top-partners__container" data-v-946ef96e>
                <div data-test-id="top-partner-item" class="top-partner-item top-partners__item custom-style3"
                 
                  data-v-15fdb478 data-v-946ef96e><img
                    src="https://res.cloudinary.com/glovoapp/q_30,f_auto,c_fill,dpr_1.0,h_128,w_128,b_transparent/Stores/txgzy5f119afvmq7znvp"
                    srcset="https://res.cloudinary.com/glovoapp/q_30,f_auto,c_fill,dpr_3.0,h_128,w_128,b_transparent/Stores/txgzy5f119afvmq7znvp 384w, https://res.cloudinary.com/glovoapp/q_30,f_auto,c_fill,dpr_2.0,h_128,w_128,b_transparent/Stores/txgzy5f119afvmq7znvp 256w, https://res.cloudinary.com/glovoapp/q_30,f_auto,c_fill,dpr_1.0,h_128,w_128,b_transparent/Stores/txgzy5f119afvmq7znvp 128w"
                    sizes="(min-width: 600px) 128px, 120px" alt="McDonald's" width="128" height="121"
                    data-test-id="top-partner-item-image" loading="lazy" class="top-partner-item__image custom-style4"
                    // style="mask-image:url('https://res.cloudinary.com/glovoapp/image/fetch/q_30,f_auto,c_lpad,dpr_1.0,h_121,w_128,b_transparent/https://glovoapp.com/images/landing/partners-mask.svg');"
                    data-v-15fdb478 />
                  <div class="top-partner-item__title-wrapper" data-v-15fdb478>
                    <h3 data-test-id="top-partner-item-title" class="top-partner-item__title" data-v-15fdb478>
                      McDonald's
                    </h3>
                  </div>
                </div>
                <div data-test-id="top-partner-item" class="top-partner-item top-partners__item"
                //   style="--mask-image:url(https://res.cloudinary.com/glovoapp/image/fetch/q_30,f_auto,c_lpad,dpr_1.0,h_121,w_128,b_transparent/https://glovoapp.com/images/landing/partners-mask.svg);"
                  data-v-15fdb478 data-v-946ef96e><img
                    src="https://res.cloudinary.com/glovoapp/q_30,f_auto,c_fill,dpr_1.0,h_128,w_128,b_transparent/Stores/u56iguujsgxttvzxpthn"
                    srcset="https://res.cloudinary.com/glovoapp/q_30,f_auto,c_fill,dpr_3.0,h_128,w_128,b_transparent/Stores/u56iguujsgxttvzxpthn 384w, https://res.cloudinary.com/glovoapp/q_30,f_auto,c_fill,dpr_2.0,h_128,w_128,b_transparent/Stores/u56iguujsgxttvzxpthn 256w, https://res.cloudinary.com/glovoapp/q_30,f_auto,c_fill,dpr_1.0,h_128,w_128,b_transparent/Stores/u56iguujsgxttvzxpthn 128w"
                    sizes="(min-width: 600px) 128px, 120px" alt="KFC" width="128" height="121"
                    data-test-id="top-partner-item-image" loading="lazy" class="top-partner-item__image"
                    // style="mask-image:url('https://res.cloudinary.com/glovoapp/image/fetch/q_30,f_auto,c_lpad,dpr_1.0,h_121,w_128,b_transparent/https://glovoapp.com/images/landing/partners-mask.svg');"
                    data-v-15fdb478 />
                  <div class="top-partner-item__title-wrapper" data-v-15fdb478>
                    <h3 data-test-id="top-partner-item-title" class="top-partner-item__title" data-v-15fdb478>
                      KFC
                    </h3>
                  </div>
                </div>
                <div data-test-id="top-partner-item" class="top-partner-item top-partners__item"
                //   style="--mask-image:url(https://res.cloudinary.com/glovoapp/image/fetch/q_30,f_auto,c_lpad,dpr_1.0,h_121,w_128,b_transparent/https://glovoapp.com/images/landing/partners-mask.svg);"
                  data-v-15fdb478 data-v-946ef96e><img
                    src="https://res.cloudinary.com/glovoapp/q_30,f_auto,c_fill,dpr_1.0,h_128,w_128,b_transparent/Stores/akivf28yymtqggy5cxzl"
                    srcset="https://res.cloudinary.com/glovoapp/q_30,f_auto,c_fill,dpr_3.0,h_128,w_128,b_transparent/Stores/akivf28yymtqggy5cxzl 384w, https://res.cloudinary.com/glovoapp/q_30,f_auto,c_fill,dpr_2.0,h_128,w_128,b_transparent/Stores/akivf28yymtqggy5cxzl 256w, https://res.cloudinary.com/glovoapp/q_30,f_auto,c_fill,dpr_1.0,h_128,w_128,b_transparent/Stores/akivf28yymtqggy5cxzl 128w"
                    sizes="(min-width: 600px) 128px, 120px" alt="BurgerKing" width="128" height="121"
                    data-test-id="top-partner-item-image" loading="lazy" class="top-partner-item__image"
                    // style="mask-image:url('https://res.cloudinary.com/glovoapp/image/fetch/q_30,f_auto,c_lpad,dpr_1.0,h_121,w_128,b_transparent/https://glovoapp.com/images/landing/partners-mask.svg');"
                    data-v-15fdb478 />
                  <div class="top-partner-item__title-wrapper" data-v-15fdb478>
                    <h3 data-test-id="top-partner-item-title" class="top-partner-item__title" data-v-15fdb478>
                      BurgerKing
                    </h3>
                  </div>
                </div>
                <div data-test-id="top-partner-item" class="top-partner-item top-partners__item"
                //   style="--mask-image:url(https://res.cloudinary.com/glovoapp/image/fetch/q_30,f_auto,c_lpad,dpr_1.0,h_121,w_128,b_transparent/https://glovoapp.com/images/landing/partners-mask.svg);"
                  data-v-15fdb478 data-v-946ef96e><img
                    src="https://res.cloudinary.com/glovoapp/q_30,f_auto,c_fill,dpr_1.0,h_128,w_128,b_transparent/Stores/hvpjrukzrzcx2fr7aivr"
                    srcset="https://res.cloudinary.com/glovoapp/q_30,f_auto,c_fill,dpr_3.0,h_128,w_128,b_transparent/Stores/hvpjrukzrzcx2fr7aivr 384w, https://res.cloudinary.com/glovoapp/q_30,f_auto,c_fill,dpr_2.0,h_128,w_128,b_transparent/Stores/hvpjrukzrzcx2fr7aivr 256w, https://res.cloudinary.com/glovoapp/q_30,f_auto,c_fill,dpr_1.0,h_128,w_128,b_transparent/Stores/hvpjrukzrzcx2fr7aivr 128w"
                    sizes="(min-width: 600px) 128px, 120px" alt="Carrefour" width="128" height="121"
                    data-test-id="top-partner-item-image" loading="lazy" class="top-partner-item__image"
                    // style="mask-image:url('https://res.cloudinary.com/glovoapp/image/fetch/q_30,f_auto,c_lpad,dpr_1.0,h_121,w_128,b_transparent/https://glovoapp.com/images/landing/partners-mask.svg');"
                    data-v-15fdb478 />
                  <div class="top-partner-item__title-wrapper" data-v-15fdb478>
                    <h3 data-test-id="top-partner-item-title" class="top-partner-item__title" data-v-15fdb478>
                      Carrefour
                    </h3>
                  </div>
                </div>
                <div data-test-id="top-partner-item" class="top-partner-item top-partners__item"
                //   style="--mask-image:url(https://res.cloudinary.com/glovoapp/image/fetch/q_30,f_auto,c_lpad,dpr_1.0,h_121,w_128,b_transparent/https://glovoapp.com/images/landing/partners-mask.svg);"
                  data-v-15fdb478 data-v-946ef96e><img
                    src="https://res.cloudinary.com/glovoapp/q_30,f_auto,c_fill,dpr_1.0,h_128,w_128,b_transparent/Stores/wbvnm1neh9bxeagj990c"
                    srcset="https://res.cloudinary.com/glovoapp/q_30,f_auto,c_fill,dpr_3.0,h_128,w_128,b_transparent/Stores/wbvnm1neh9bxeagj990c 384w, https://res.cloudinary.com/glovoapp/q_30,f_auto,c_fill,dpr_2.0,h_128,w_128,b_transparent/Stores/wbvnm1neh9bxeagj990c 256w, https://res.cloudinary.com/glovoapp/q_30,f_auto,c_fill,dpr_1.0,h_128,w_128,b_transparent/Stores/wbvnm1neh9bxeagj990c 128w"
                    sizes="(min-width: 600px) 128px, 120px" alt="PizzaHut" width="128" height="121"
                    data-test-id="top-partner-item-image" loading="lazy" class="top-partner-item__image"
                    // style="mask-image:url('https://res.cloudinary.com/glovoapp/image/fetch/q_30,f_auto,c_lpad,dpr_1.0,h_121,w_128,b_transparent/https://glovoapp.com/images/landing/partners-mask.svg');"
                    data-v-15fdb478 />
                  <div class="top-partner-item__title-wrapper" data-v-15fdb478>
                    <h3 data-test-id="top-partner-item-title" class="top-partner-item__title" data-v-15fdb478>
                      PizzaHut
                    </h3>
                  </div>
                </div>
                <div data-test-id="top-partner-item" class="top-partner-item top-partners__item"
                //   style="--mask-image:url(https://res.cloudinary.com/glovoapp/image/fetch/q_30,f_auto,c_lpad,dpr_1.0,h_121,w_128,b_transparent/https://glovoapp.com/images/landing/partners-mask.svg);"
                  data-v-15fdb478 data-v-946ef96e><img
                    src="https://res.cloudinary.com/glovoapp/q_30,f_auto,c_fill,dpr_1.0,h_128,w_128,b_transparent/Stores/igq1ghjtalh1hzzu3wx1"
                    srcset="https://res.cloudinary.com/glovoapp/q_30,f_auto,c_fill,dpr_3.0,h_128,w_128,b_transparent/Stores/igq1ghjtalh1hzzu3wx1 384w, https://res.cloudinary.com/glovoapp/q_30,f_auto,c_fill,dpr_2.0,h_128,w_128,b_transparent/Stores/igq1ghjtalh1hzzu3wx1 256w, https://res.cloudinary.com/glovoapp/q_30,f_auto,c_fill,dpr_1.0,h_128,w_128,b_transparent/Stores/igq1ghjtalh1hzzu3wx1 128w"
                    sizes="(min-width: 600px) 128px, 120px" alt="PapaJohn's" width="128" height="121"
                    data-test-id="top-partner-item-image" loading="lazy" class="top-partner-item__image"
                    // style="mask-image:url('https://res.cloudinary.com/glovoapp/image/fetch/q_30,f_auto,c_lpad,dpr_1.0,h_121,w_128,b_transparent/https://glovoapp.com/images/landing/partners-mask.svg');"
                    data-v-15fdb478 />
                  <div class="top-partner-item__title-wrapper" data-v-15fdb478>
                    <h3 data-test-id="top-partner-item-title" class="top-partner-item__title" data-v-15fdb478>
                      PapaJohn's
                    </h3>
                  </div>
                </div>
                <div data-test-id="top-partner-item" class="top-partner-item top-partners__item"
                //   style="--mask-image:url(https://res.cloudinary.com/glovoapp/image/fetch/q_30,f_auto,c_lpad,dpr_1.0,h_121,w_128,b_transparent/https://glovoapp.com/images/landing/partners-mask.svg);"
                  data-v-15fdb478 data-v-946ef96e><img
                    src="https://res.cloudinary.com/glovoapp/q_30,f_auto,c_fill,dpr_1.0,h_128,w_128,b_transparent/Stores/vqhe8yxwvrbjq7dgjndb"
                    srcset="https://res.cloudinary.com/glovoapp/q_30,f_auto,c_fill,dpr_3.0,h_128,w_128,b_transparent/Stores/vqhe8yxwvrbjq7dgjndb 384w, https://res.cloudinary.com/glovoapp/q_30,f_auto,c_fill,dpr_2.0,h_128,w_128,b_transparent/Stores/vqhe8yxwvrbjq7dgjndb 256w, https://res.cloudinary.com/glovoapp/q_30,f_auto,c_fill,dpr_1.0,h_128,w_128,b_transparent/Stores/vqhe8yxwvrbjq7dgjndb 128w"
                    sizes="(min-width: 600px) 128px, 120px" alt="Subway" width="128" height="121"
                    data-test-id="top-partner-item-image" loading="lazy" class="top-partner-item__image"
                    // style="mask-image:url('https://res.cloudinary.com/glovoapp/image/fetch/q_30,f_auto,c_lpad,dpr_1.0,h_121,w_128,b_transparent/https://glovoapp.com/images/landing/partners-mask.svg');"
                    data-v-15fdb478 />
                  <div class="top-partner-item__title-wrapper" data-v-15fdb478>
                    <h3 data-test-id="top-partner-item-title" class="top-partner-item__title" data-v-15fdb478>
                      Subway
                    </h3>
                  </div>
                </div>
                <div data-test-id="top-partner-item" class="top-partner-item top-partners__item"
                //   style="--mask-image:url(https://res.cloudinary.com/glovoapp/image/fetch/q_30,f_auto,c_lpad,dpr_1.0,h_121,w_128,b_transparent/https://glovoapp.com/images/landing/partners-mask.svg);"
                  data-v-15fdb478 data-v-946ef96e><img
                    src="https://res.cloudinary.com/glovoapp/q_30,f_auto,c_fill,dpr_1.0,h_128,w_128,b_transparent/Stores/zx7udzwuopreaoqarfhj"
                    srcset="https://res.cloudinary.com/glovoapp/q_30,f_auto,c_fill,dpr_3.0,h_128,w_128,b_transparent/Stores/zx7udzwuopreaoqarfhj 384w, https://res.cloudinary.com/glovoapp/q_30,f_auto,c_fill,dpr_2.0,h_128,w_128,b_transparent/Stores/zx7udzwuopreaoqarfhj 256w, https://res.cloudinary.com/glovoapp/q_30,f_auto,c_fill,dpr_1.0,h_128,w_128,b_transparent/Stores/zx7udzwuopreaoqarfhj 128w"
                    sizes="(min-width: 600px) 128px, 120px" alt="TacoBell" width="128" height="121"
                    data-test-id="top-partner-item-image" loading="lazy" class="top-partner-item__image"
                    // style="mask-image:url('https://res.cloudinary.com/glovoapp/image/fetch/q_30,f_auto,c_lpad,dpr_1.0,h_121,w_128,b_transparent/https://glovoapp.com/images/landing/partners-mask.svg');"
                    data-v-15fdb478 /> 
                  <div class="top-partner-item__title-wrapper" data-v-15fdb478>
                    <h3 data-test-id="top-partner-item-title" class="top-partner-item__title" data-v-15fdb478>
                      TacoBell
                    </h3>
                  </div>
                </div>
              </div>
            </div>
            <div data-test-id="why-glovo-container" class="why-glovo" data-v-3ded4933>
              <h2 class="heading-h2 why-glovo__title heading-h2--bigger" data-v-2013283c data-v-3ded4933>
                Anything delivered
              </h2>
              <div class="why-glovo__columns" data-v-3ded4933>
                <div data-test-id="why-glovo-column" class="why-glovo-element"
                //  style="order:1;" 
                 data-v-5f9b52b0
                  data-v-3ded4933><img
                    src="https://res.cloudinary.com/glovoapp/image/fetch/f_svg,q_auto:low,h_136,w_170/https://glovoapp.com/images/why-glovo/restaurants.svg"
                    role="presentation" data-test-id="why-glovo-element-image" width="170" height="136"
                    class="why-glovo-element__image" data-v-5f9b52b0 />
                  <h3 data-test-id="why-glovo-element-title" class="why-glovo-element__title" data-v-5f9b52b0>
                    Your city's top restaurants
                  </h3>
                  <div data-test-id="why-glovo-element-description" class="why-glovo-element__description"
                    data-v-5f9b52b0 >With a great variety of restaurants you can order your favourite
                    food or <span class="v-highlight v-highlight--yellow  v-highlight--regular v-highlight--undefined"
                      data-v-5f9b52b0><mark class="v-highlight-text v-highlight-text--regular"><span>explore new
                          restaurants nearby!</span></mark></span></div>
                </div>
                <div data-test-id="why-glovo-column" class="why-glovo-element" style={{order:"3"}} data-v-5f9b52b0
                  data-v-3ded4933><img
                    src={delivery}
                    role="presentation" data-test-id="why-glovo-element-image" width="170" height="136"
                    class="why-glovo-element__image" data-v-5f9b52b0 />
                  <h3 data-test-id="why-glovo-element-title" class="why-glovo-element__title" data-v-5f9b52b0>
                    Fast delivery
                  </h3>
                  <div data-test-id="why-glovo-element-description" class="why-glovo-element__description"
                    data-v-5f9b52b0 >Like a flash! Order or send anything in your city and <span
                      class="v-highlight v-highlight--yellow  v-highlight--regular v-highlight--undefined"
                      data-v-5f9b52b0><mark class="v-highlight-text v-highlight-text--regular"><span>receive it in
                          minutes</span></mark></span></div>
                </div>
                <div data-test-id="why-glovo-column" class="why-glovo-element" style={{order:"4"}} data-v-5f9b52b0
                  data-v-3ded4933><img
                    src={groceries}
                    role="presentation" data-test-id="why-glovo-element-image" width="170" height="136"
                    class="why-glovo-element__image" data-v-5f9b52b0 />
                  <h3 data-test-id="why-glovo-element-title" class="why-glovo-element__title" data-v-5f9b52b0>
                    Food delivery &amp; more
                  </h3>
                  <div data-test-id="why-glovo-element-description" class="why-glovo-element__description"
                    data-v-5f9b52b0 >Find anything you need! From <span
                      class="v-highlight v-highlight--yellow  v-highlight--regular v-highlight--undefined"
                      data-v-5f9b52b0><mark class="v-highlight-text v-highlight-text--regular"><span>from indian cusines to chines </span></mark></span> — if it's in your city order it and receive
                    it.</div>
                </div>
                <div class="why-glovo__button-wrapper" data-v-3ded4933><button type="button" 
                    data-test-id="why-glovo-button" class="helio-button why-glovo__button unelevatedMedium"
                    data-v-25ebbc26 data-v-3ded4933>  <span class="helio-button__content" data-v-25ebbc26>
                      Explore restaurents around you
                    </span></button></div>
              </div>
            </div>
            <div class="landing-links-wrapper landing-links-wrapper--top-and-bottom"
            //   style="--waves-desktop:url(https://res.cloudinary.com/glovoapp/image/fetch/f_svg,q_auto:low/https://glovoapp.com/images/landing/waves/landing-links-waves-desktop.svg);--waves-mobile:url(https://res.cloudinary.com/glovoapp/image/fetch/f_svg,q_auto:low/https://glovoapp.com/images/landing/waves/landing-links-waves-mobile.svg);--bottom-wave-desktop:url(https://res.cloudinary.com/glovoapp/image/fetch/f_svg,q_auto:low/https://glovoapp.com/images/waves/address-jumbotron-wave-desktop.svg);--bottom-wave-mobile:url(https://res.cloudinary.com/glovoapp/image/fetch/f_svg,q_auto:low/https://glovoapp.com/images/waves/address-jumbotron-wave-mobile.svg);"
              data-v-4f85d6a8>
              <div data-test-id="location-links" class="location-links" data-v-0de93228><img
                  src={countries}
                  alt="Countries where we deliver" data-test-id="location-title-image" width="140" height="140"
                  role="presentation" loading="lazy" data-v-0de93228 />
                <h2 data-test-id="location-title" class="heading-h2 location-links__title heading-h2--bigger"
                  data-v-2013283c data-v-0de93228>
                  Cities where we deliver
                </h2>
                <div data-test-id="location-link-container" class="location-links__links-container" data-v-0de93228><a
                    href="es/es/index.html" data-test-id="location-link" class="location-links__link" data-v-0de93228>
                    Bengluru
                  </a><a href="it/it/index.html" data-test-id="location-link" class="location-links__link"
                    data-v-0de93228>
                    Chennai
                  </a><a href="ua/uk/index.html" data-test-id="location-link" class="location-links__link"
                    data-v-0de93228>
                    Madurai
                  </a><a href="ro/ro/index.html" data-test-id="location-link" class="location-links__link"
                    data-v-0de93228>
                    Bengluru
                  </a><a href="ge/ka/index.html" data-test-id="location-link" class="location-links__link"
                    data-v-0de93228>
                    Madurai
                  </a><a href="pt/pt/index.html" data-test-id="location-link" class="location-links__link"
                    data-v-0de93228>
                    Chennai
                  </a><a href="pl/pl/index.html" data-test-id="location-link" class="location-links__link"
                    data-v-0de93228>
                    Bengluru
                  </a></div> 
              </div>
            </div>
            
            <DownloadApp />
            <div data-test-id="corporate-container" class="corporate-container"
            //   style="--corporate-wave-desktop:url('https://res.cloudinary.com/glovoapp/image/fetch/f_svg,q_auto:low/https://glovoapp.com/images/landing/waves/corporate-wave-desktop.svg');"
              data-v-4a724e07><img
                src="https://res.cloudinary.com/glovoapp/image/fetch/f_svg,c_lpad,q_auto:low,h_80,w_117,b_transparent/https://glovoapp.com/images/corporate-container/together.svg"
                width="117" height="80" role="presentation" data-test-id="corporate-icon" loading="lazy"
                class="corporate-container__icon" data-v-4a724e07 />
              <div class="corporate-container__wrapper" data-v-4a724e07>
                <h2 data-test-id="corporate-title" class="heading-h2 corporate-container__title heading-h2--bigger"
                  data-v-2013283c data-v-4a724e07>
                  Let’s do it together
                </h2>
                <div class="corporate-container__content" data-v-4a724e07>
                  <div data-test-id="corporate-element" class="corporate-element" data-v-4a724e07>
                    <div class="corporate-element__top"><img
                        src={malgo3}
                    
                        sizes="(min-width: 600px) 254px, calc(100vw - 48px)" width="254" height="220"
                        alt="Become a rider" data-test-id="corporate-element-image" loading="lazy"
                        class="corporate-element__image" />
                      <div class="corporate-element__text">
                        <h3 data-test-id="corporate-element-title" class="corporate-element__title">
                          Become a rider
                        </h3>
                        <div data-test-id="corporate-element-description" class="corporate-element__description">
                          Enjoy flexibility, freedom and competitive earnings by delivering through Malgo Eats.
                        </div>
                      </div>
                    </div> <a data-test-id="corporate-element-link"
                      class="corporate-element__link"><button onClick={() => routeChange('/become-delivery')} type="button"
                        class="helio-button corporate-element__button primary" data-v-25ebbc26>  <span
                          class="helio-button__content" data-v-25ebbc26>
                          Register here
                        </span></button></a>
                  </div>
                  <div data-test-id="corporate-element" class="corporate-element" data-v-4a724e07>
                    <div class="corporate-element__top"><img
                        src={malgoRes}
                        sizes="(min-width: 600px) 254px, calc(100vw - 48px)" width="254" height="220"
                        alt="Become a partner" data-test-id="corporate-element-image" loading="lazy"
                        class="corporate-element__image" />
                      <div class="corporate-element__text">
                        <h3 data-test-id="corporate-element-title" class="corporate-element__title">
                          Add Your Restaurant
                        </h3>
                        <div data-test-id="corporate-element-description" class="corporate-element__description">
                          Grow with Malgo Eats! Our technology and user base can help you boost sales and unlock new
                          opportunities!
                        </div>
                      </div>
                    </div> <a data-test-id="corporate-element-link" 
                      class="corporate-element__link"><button onClick={() => routeChange('/become-partner')} type="button" 
                        class="helio-button corporate-element__button primary" data-v-25ebbc26>  <span
                          class="helio-button__content" data-v-25ebbc26>
                          Register here
                        </span></button></a>
                  </div>
                  <div data-test-id="corporate-element" class="corporate-element" data-v-4a724e07>
                    <div class="corporate-element__top"><img
                        src={malgo1}
                       
                        sizes="(min-width: 600px) 254px, calc(100vw - 48px)" width="254" height="220" alt="Become a Franchisee"
                        data-test-id="corporate-element-image" loading="lazy" class="corporate-element__image" />
                      <div class="corporate-element__text">
                        <h3 data-test-id="corporate-element-title" class="corporate-element__title">
                          Become a Franchisee
                        </h3>
                        <div data-test-id="corporate-element-description" class="corporate-element__description">
                          Ready for an exciting new challenge? If you’re ambitious, humble, and love working with
                          others, then we want to hear from you!
                        </div>
                      </div>
                    </div> <a data-test-id="corporate-element-link"
                      class="corporate-element__link"><button onClick={() => routeChange('/become-franchise')} type="button"
                        class="helio-button corporate-element__button primary" data-v-25ebbc26>  <span
                          class="helio-button__content" data-v-25ebbc26>
                          Register here
                        </span></button></a>
                  </div>
                </div>
              </div>
            </div>
          </div>
     
         <Footer />
        </div> 
        <div data-test-id="modal-overlay" class="modal-overlay"
        //  style="display:none;"
        style={{display:"none"}}
          data-v-60bc0390
          data-v-5e877c34></div>  
      </div>
    </div>
  </div>
</body>
</Fragment>
)
}
