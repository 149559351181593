import logoimg from "../Assets/images/Malgo_Eats.png";
import { useNavigate } from "react-router-dom";
import franchiseIcon from "../Assets/images/franchise.svg"
import brokerIcon from "../Assets/images/brokerIcon.svg"
import deliveryIcon from "../Assets/images/delivery-van.svg"

export const Header = () => {
    console.log('header_added')
    let navigate = useNavigate(); 
    const routeChange = (path) =>{ 
        console.log(path,'path_test')
        navigate(path);
    }

    return (
    <header data-test-id="global-header" data-v-0461e8e8 data-v-4ce35e82>

        <div class="header-wrapper-scroll-aware" data-v-0461e8e8>
        <div class="header-bar header-bar--mobile-no-shadow header-bar--default" data-v-18e417f0
            data-v-4ce35e82>
            <div class="header-bar__content" data-v-18e417f0><a onClick={() => routeChange('/')} href="#" aria-current="page"
                aria-label="Home"
                class="header-logo header-global-country__logo nuxt-link-exact-active nuxt-link-active"
                data-v-94e22206 data-v-4ce35e82><img src={logoimg} class="logomain" /></a>
            <div class="header-global-country__text-wrapper" data-v-18e417f0 data-v-4ce35e82>
                <div
                class="header-address-input-text header-global-country__text header-global-country__text--desktop"
                    data-v-4ce35e82>Enter your address to know <span
                    class="v-highlight v-highlight--white  v-highlight--subtitle v-highlight--bold"
                    ><mark class="v-highlight-text v-highlight-text--subtitle"><span>what’s near
                        you</span></mark></span></div>
            </div>
            <div  data-test-id="header-actions" class="header-global-country__actions" data-v-0b6a617a
                data-v-4ce35e82>
                <div data-test-id="header-actions-mobile"
                class="mobile-menu header-actions--mobile mobile-menu--hasButton" data-v-ce8abcc2
                data-v-0b6a617a>
                <div data-v-ce8abcc2><button onClick={() => routeChange('/become-partner')}  type="button"  data-test-id="get-started-button"
                    id="user-register" class="helio-button get-started-button mobile-menu--button pill"
                    data-v-25ebbc26 data-v-3d748f89 data-v-ce8abcc2>
                        <span class="helio-button__content" data-v-25ebbc26>
                        <img src={brokerIcon} title="Become Partner" />
                    </span></button> </div>  
                    
                    
                </div>
                
                <div data-test-id="header-actions-mobile"
                class="mobile-menu header-actions--mobile mobile-menu--hasButton" data-v-ce8abcc2
                data-v-0b6a617a>
                <div data-v-ce8abcc2><button type="button" onClick={() => routeChange('/become-franchise')} data-test-id="get-started-button"
                    id="user-register" class="helio-button get-started-button mobile-menu--button pill"
                    data-v-25ebbc26 data-v-3d748f89 data-v-ce8abcc2>  <span
                        class="helio-button__content" data-v-25ebbc26>
                        <img src={franchiseIcon} title="Become Franchise" />
                    </span></button> </div>  
                </div>

                <div data-test-id="header-actions-mobile"
                class="mobile-menu header-actions--mobile mobile-menu--hasButton" data-v-ce8abcc2
                data-v-0b6a617a>
                <div data-v-ce8abcc2><button type="button" onClick={() => routeChange('/become-delivery')} data-test-id="get-started-button"
                    id="user-register" class="helio-button get-started-button mobile-menu--button pill"
                    data-v-25ebbc26 data-v-3d748f89 data-v-ce8abcc2>  <span
                        class="helio-button__content" data-v-25ebbc26>
                        <img src={deliveryIcon} title="Become Delivery Partner" />
                    </span></button> </div>  
                </div>
                
                
                <div data-test-id="header-actions-desktop" class="header-actions-desktop header-actions--desktop"
                data-v-bd4115c8 data-v-0b6a617a> <a onClick={() => routeChange('/become-partner')}  
                    data-test-id="get-started-button" id="user-register" data-e2e-id="header-user-signup"
                    class="helio-button get-started-button header-actions-desktop__button primary" data-v-25ebbc26
                    data-v-3d748f89 data-v-bd4115c8>  <span class="helio-button__content"
                    data-v-25ebbc26>
                    </span>
                        <img src={brokerIcon} title="Become Partner" />
                    </a> </div>
                    
                    
                    <div data-test-id="header-actions-desktop" class="header-actions-desktop header-actions--desktop"
                data-v-bd4115c8 data-v-0b6a617a> <a href="#" onClick={() => routeChange('/become-franchise')} 
                    data-test-id="get-started-button" id="user-register" data-e2e-id="header-user-signup"
                    class="helio-button get-started-button header-actions-desktop__button primary" data-v-25ebbc26
                    data-v-3d748f89 data-v-bd4115c8>  <span class="helio-button__content"
                    data-v-25ebbc26>
                        <img src={franchiseIcon} title="Become Franchise" />
                    </span></a> </div>
                    <div data-test-id="header-actions-desktop" class="header-actions-desktop header-actions--desktop"
                data-v-bd4115c8 data-v-0b6a617a> <a href="#" onClick={() => routeChange('/become-delivery')} 
                    data-test-id="get-started-button" id="user-register" data-e2e-id="header-user-signup"
                    class="helio-button get-started-button header-actions-desktop__button primary" data-v-25ebbc26
                    data-v-3d748f89 data-v-bd4115c8>  <span class="helio-button__content"
                    data-v-25ebbc26>
                        <img src={deliveryIcon} title="Become Delivery Partner" />
                    </span></a> </div>
            </div>
            </div>
        </div>
        <div class="header-drawer header-global-country__drawer" data-v-4ce35e82>
            <div class="header-drawer__inside">
            <div
                class="header-address-input-text header-global-country__text header-global-country__text--in-drawer"
                data-v-4ce35e82>Enter your address to know <span
                class="v-highlight v-highlight--white  v-highlight--subtitle v-highlight--bold"
                ><mark class="v-highlight-text v-highlight-text--subtitle"><span>what’s near
                    you</span></mark></span></div>
            <div class="header-address-input header-global-country__input--in-drawer" data-v-57d66229
                data-v-4ce35e82>
                <div data-test-id="header-address-input-element" class="header-address-input__input-element"
                data-v-57d66229>
                <div class="header-address-input__icon-wrapper" data-v-57d66229><img
                    src="https://res.cloudinary.com/glovoapp/image/fetch/q_30,f_auto,c_lpad,dpr_1.0,h_18,w_18,b_transparent/https://glovoapp.com/images/flag.svg"
                    height="18" width="18" role="presentation" data-test-id="header-address-input-icon"
                    class="header-address-input__icon" data-v-57d66229 /></div>
                <div data-test-id="header-address-input-placeholder" class="header-address-input__placeholder"
                    data-v-57d66229>
                    What's your address?
                </div>
                </div>
            </div>
            </div>
        </div>
        </div>
        <div data-test-id="header-scroll-observer"
        class="header-wrapper-scroll-aware__scroll-observer header-wrapper-scroll-aware__scroll-observer--default"
        data-v-0461e8e8>
        </div>
  </header>)
}