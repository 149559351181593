import logoimg from "../Assets/images/Malgo_Eats.png";

export const Footer = () => {
    return (
        <footer class="app-footer hidden-mobile-when-search" data-v-42e6316f data-v-5e877c34>
            <div class="app-footer__container" data-v-42e6316f>
              {/* <img
                src={logoimg}
                alt="Malgo Eats" data-test-id="footer-logo" width="115" height="39" loading="lazy" class="footer-logo"
                data-v-e814c496 data-v-42e6316f /> */}
                <h2 style={{color:'#fff',fontSize:'20px',fontWeight:800}}>Know about Malgo Eats</h2>
                <span className="download-app__subtitle" style={{color:'#ccc',lineHeight:'30px'}}>Malgo Eats and all its subsidaries are trademarked under ACW TRADERS PRIVATE LIMITED. Any attempt to copy its logo/color scheme or brand name without genuine license is a punishable offence as per the constitution.</span>
              <div class="app-footer__grid" data-v-42e6316f>
              
                <div data-test-id="footer-column" class="footer-column" data-v-75430ada data-v-42e6316f>
                  <div data-test-id="column-title" class="footer-column__title" data-v-75430ada>
                    Let’s do it together
                  </div> <a data-test-id="footer-link" class="footer-column__link"
                    data-v-75430ada>
                    Careers
                  </a><a data-test-id="footer-link" class="footer-column__link"
                    data-v-75430ada>
                    Malgo Eats for Partners
                  </a><a data-test-id="footer-link" class="footer-column__link"
                    data-v-75430ada>
                    Couriers
                  </a><a data-test-id="footer-link" class="footer-column__link"
                    data-v-75430ada>
                    Malgo Eats Business
                  </a> 
                </div>
                <div data-test-id="footer-column" class="footer-column" data-v-75430ada data-v-42e6316f>
                  <div data-test-id="column-title" class="footer-column__title" data-v-75430ada>
                    Links of interest
                  </div> <a data-test-id="footer-link" href="#" class="footer-column__link"
                    data-v-75430ada>
                    About us
                  </a><a data-test-id="footer-link" href="#" class="footer-column__link"
                    data-v-75430ada>
                    FAQ
                  </a><span data-test-id="footer-support" role="button" class="footer-column__link" data-v-75430ada>
                    Contact us
                  </span><a href="en/security/index.html" data-test-id="footer-link" class="footer-column__link"
                    data-v-75430ada>
                    Security
                  </a> 
                </div>
                <div data-test-id="footer-column" class="footer-column" data-v-75430ada data-v-42e6316f>
                  <div data-test-id="column-title" class="footer-column__title" data-v-75430ada>
                    Follow us
                  </div> <a data-test-id="footer-link" target="_blank" rel="noopener"
                    href="#" class="footer-column__link" data-v-75430ada>
                    Facebook
                  </a><a data-test-id="footer-link" target="_blank" rel="noopener" href="#"
                    class="footer-column__link" data-v-75430ada>
                    Twitter
                  </a><a data-test-id="footer-link" target="_blank" rel="noopener"
                    href="#" class="footer-column__link" data-v-75430ada>
                    Instagram
                  </a> 
                </div> 
                <div data-test-id="footer-column"
                  class="footer-column footer-column-with-downloads app-footer__column-with-downloads--desktop footer-column--with-download-buttons"
                  data-v-75430ada data-v-10af60be data-v-42e6316f><div data-test-id="column-title" class="footer-column__title" data-v-75430ada>
                    Reach Us
                  </div>
                  <p style={{color:'#fff'}}>#A1, Veerasamy Street </p>
  <p style={{color:'#fff'}}>Big Bazzar Road Arani, </p>
<p style={{color:'#fff'}}> Tamil Nadu, India </p>
<p style={{color:'#fff'}}>+91 9894 529 833</p>
                  {/* <a
                    data-test-id="footer-link" href="docs/en/legal/terms/index.html" class="footer-column__link"
                    data-v-75430ada>
                    Terms &amp; Conditions
                  </a><a data-test-id="footer-link" href="docs/en/legal/privacy/index.html" class="footer-column__link"
                    data-v-75430ada>
                    Privacy Policy
                  </a><a href="en/legal/cookies/index.html" data-test-id="footer-link" class="footer-column__link"
                    data-v-75430ada>
                    Cookies Policy
                  </a><a data-test-id="footer-link" href="https://compliance.glovoapp.com/public/home"
                    class="footer-column__link" data-v-75430ada>
                    Compliance
                  </a>  */}
                </div>
              </div> 
              <div data-test-id="language-picker" class="language-picker" data-v-42e6316f> </div>
            </div>
</footer>
    )

 }
